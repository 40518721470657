import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';

const ChosenPlan = () => {
    const location = useLocation();
    const { plan } = location.state;
    const user = useSelector((state) => state.auth.user);
    const userId = user ? user._id : null;
    const [phone, setPhone] = useState('');
    const [loading, setLoading] = useState(false);

    const validatePhone = (phone) => {
        const phoneRegex = /^(07|01)\d{8}$/; // Kenyan phone number validation
        return phoneRegex.test(phone);
    };

    const handlePay = async (e) => {
        e.preventDefault();
        if (!validatePhone(phone)) {
            toast.error('Please enter a valid Kenyan phone number starting with 254.');
            return;
        }

        setLoading(true);

        const form = {
            MerchantCode: "600980",
            NetworkCode: "63902",
            PhoneNumber: phone,
            TransactionDesc: "billing",
            Currency: "KES",
            AccountReference: '254112163919',
            Amount: plan.price,
            userId: userId,
            planid: plan._id
        };

        try {
            const response = await axios.post("https://uzima-backe.vercel.app/api/payment/requestpayment", form);
            const checkoutid = response.data.CheckoutRequestID;
            console.log("checkout", checkoutid);
            toast.success('Payment request sent successfully!');
        } catch (error) {
            toast.error('Error requesting payment, please try again.');
            console.log("error requesting payment", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="min-h-screen w-full bg-gray-50 flex flex-col items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full space-y-8">
                <div className="text-center">
                    <h2 className="text-3xl font-extrabold text-gray-900">
                        Payment for {plan.name} Plan
                    </h2>
                    <p className="mt-2 text-sm text-gray-600">
                        Price: KES {plan.price}
                    </p>
                </div>

                <form className="mt-8 space-y-6" onSubmit={handlePay}>
                    <div className="rounded-md shadow-sm -space-y-px">
                        <div>
                            <label htmlFor="phone" className="sr-only">Phone Number</label>
                            <input
                                id="phone"
                                name="phone"
                                type="text"
                                autoComplete="tel"
                                required
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                placeholder="Enter phone number (start with 07../01...)"
                                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                disabled={loading}
                            />
                        </div>
                    </div>

                    <div>
                        <button
                            type="submit"
                            className={`group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${loading ? 'cursor-not-allowed opacity-50' : ''}`}
                            disabled={loading}
                        >
                            {loading ? 'Processing...' : 'Pay Now'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ChosenPlan;
