import React from 'react'

const PreferencesPage = () => {
  return (
    <div className='p-[40px] w-full h-[100vh]'>
        <div className='border-b border-gray-500 pb-4'>
            <h1 className='text-2xl font-bold'>Preferences</h1>
            <p className='text-gray-500 text-sm'>Configure personal preferences to your liking.</p>
        </div>
        <div>
            
        </div>
    </div>
  )
}

export default PreferencesPage