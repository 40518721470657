import React, { useState } from 'react';
import ChatNavbar from '../../components/ChatNavbar';
import ChatSidebar from '../../components/ChatSidebar';

const UserLayout = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="w-full">
      {/* Navbar */}
      <ChatNavbar toggleSidebar={toggleSidebar} />
      
      <div className="flex w-full">
        {/* Sidebar - hidden on small screens, toggleable on larger screens */}
        <div className="lg:block">
          <ChatSidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        </div>
        
        {/* Content */}
        <div className="w-full lg:w-[85%]">
          {children}
        </div>
      </div>
    </div>
  );
};

export default UserLayout;
